(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .directive('docLink', docLink)
    	.directive('onFinishRenderFix', onFinishRenderFix);
    	
    	//docLink.$inject = ['$location'];
    
    	function docLink() {
    		
    		var directive = {
    			template: '<a ui-sref="{{link}}">{{ innerText }}</a>',
    			replace: true,
    			restrict: 'EA',
    			scope: {
    				docId: '@docId',
    				docCode: '@docCode',
    				innerText: '@innerText'
    			},
    			controller: ['$scope', function($scope) {
    				
    				switch($scope.docCode.toUpperCase()){
    					case "APPLICATION":
    						$scope.link = "modules.applications.detail({id:"+ $scope.docId+"})";
    						break;
    				}
    		
    			}]
    		};

    		return directive;
    	}
    	
    	function onFinishRenderFix(){
    		return function(scope, element, attrs){
                //var isTouchDevice = 'ontouchstart' in document.documentElement;
                //After last item is rendered, do the enclosed things
//                if(scope.$last && isTouchDevice === true){
//                    $('.overlay').removeClass('touch-placeholder');
//                    $('.button-planes').remove();
//                    $('.button-cars').remove();
//                    //Other things you want to do  
//                }
    			
    			var input = angular.element(element);
    			input.addClass("k-textboxssss");
            }
    	}
})();
