(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('ConditionsLoanController', ConditionsLoanController);

    ConditionsLoanController.$inject = ['$scope', '$state'];

    function ConditionsLoanController ($scope, $state) {
        var vm = this;
        console.log("Loan Conditions Controller started");
        
        
    }
})();
