(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('CardEditPinLoanController', CardEditPinLoanController);

    CardEditPinLoanController.$inject = ['$scope', '$state', 'Card', 'formHelper', 'entity', '$stateParams', '$filter'];

    function CardEditPinLoanController ($scope, $state, Card, formHelper, entity, $stateParams, $filter) {
        var vm = this;
        vm.kendo = kendo;
        console.log("CardEditPinLoanController");
        
        console.log($stateParams.id);
        if($stateParams.id != null) {
        vm.cardholder = entity.cardholder[0];
        vm.cards = vm.cardholder.card;
        
        vm.currentCard = $filter('filter')(vm.cards, {token: $stateParams.id}, false)[0];
        }
        
        vm.save = function(){
        	$state.go('modules.loan.card.list.view');
        };
        
        vm.cancel = function(){
        	$state.go('modules.loan.card.list.view');
        };
        
    }
    
})();
