(function() {
	'use strict';
	angular.module('sigmaApp').factory('Card', Card);

	Card.$inject = [ '$resource', 'baseUrl', 'DateUtils', '$filter'];

	function Card($resource, baseUrl, DateUtils, $filter) {
		var resourceUrl = baseUrl + 'api/cards/:token';

		return $resource(resourceUrl,{},
				{
					'query' : {
						method : 'GET',
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'get' : {
						method : 'GET',
						isArray : false,
						transformResponse : function(data) {
							if (data) {
								data = angular.fromJson(data);
							}
							return data;
						}
					},
					'getDefault' : {
						method : 'GET',
						url: resourceUrl + '/default',
						isArray : false,
						transformResponse : function(data) { 
							if (data) {
								data = angular.fromJson(data);
							}
							return data;
						}
					},
					'update': {
						method:'PUT',
						transformRequest : function(data) {
		//					data.applDate = DateUtils.convertLocalDateToServer(data.applDate);
							return angular.toJson(data);
						},
						transformResponse : function(data) {
							var response = {};
							if(data) {
								response = angular.fromJson(data);
							}
							return response;
						}
					},
					'save' : {
						method : 'POST',
						transformRequest : function(data) {
							data.applDate = DateUtils.convertLocalDateToServer(data.applDate);
							return angular.toJson(data);
						},
						transformResponse : function(data) {
							var response = {};
							if(data) {
								response = angular.fromJson(data)
							}
							return response;
						}
					},
					'status': {
						method:'PUT',
						uri: resourceUrl + '/status',
						transformRequest : function(data) {
//							data.applDate = DateUtils.convertLocalDateToServer(data.applDate);
							return angular.toJson(data);
						},
						transformResponse : function(data) {
							var response = {};
							if(data) {
								response = angular.fromJson(data);
							}
							return response;
						}
					},
					'pin': {
						method:'PUT',
						uri: resourceUrl + '/pin',
						transformRequest : function(data) {
//							data.applDate = DateUtils.convertLocalDateToServer(data.applDate);
							return angular.toJson(data);
						},
						transformResponse : function(data) {
							var response = {};
							if(data) {
								response = angular.fromJson(data);
							}
							return response;
						}
					}
					
				});
	}
})();