(function() {
	'use strict';
	angular.module('sigmaApp').factory('Loan', Loan);

	Loan.$inject = [ '$resource', 'baseUrl', 'DateUtils'];

	function Loan($resource, baseUrl, DateUtils) {
		var resourceUrl = baseUrl + 'api/loans/:id';

		return $resource(resourceUrl,{},
				{
					'query' : {
						method : 'GET',
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'get' : {
						method : 'GET',
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					}
					
				});
	}
})();