(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('CardListLoanController', CardListLoanController);

    CardListLoanController.$inject = ['$scope', '$state', 'Card', 'formHelper', 'entity'];

    function CardListLoanController ($scope, $state, Card, formHelper, entity) {
        var vm = this;
        vm.kendo = kendo;
        console.log("Card List controller");
        vm.cardholder = entity.cardholder[0];
        vm.cards = vm.cardholder.card;
        
        
        vm.cardsDataSource = new kendo.data.DataSource({
			data: vm.cards
        });
        
        vm.cardsOptions = {
        		scrollable: true,
                sortable: true,                
                filterable: false,
                toolbar: [
                          { 
		                    template: "<span style='line-height:22px;' translate='modules.loan.cards_list'></span>"
		                    		 + "<button style='margin-right:1em;' class='pull-right btn btn-default btn-xs' ui-sref='modules.loan.card.list.add-card'>"
		                    		 + "<span class='glyphicon glyphicon-plus'></span>"
		                             + "&nbsp;<span translate='modules.loan.addCard'>"
		                             + "</span></button>"
                          }],
                columns: [{
	                    field: "token",
	                    headerTemplate: "<span translate='modules.loan.table.token'>",
	                    

                    },{
                        field: "stat_code",
                        headerTemplate: "<span translate='modules.loan.table.stat_code'>",
                        minScreenWidth: 992,
                        width: "15ch"
                    },{
                        field: "assigned_limit",
                        headerTemplate: "<span translate='modules.loan.table.assigned_limit'>",
                        width: "15ch",
                        format: "{0:n2}",
                        attributes: {class:"text-right"},
                        filterable: false
                    },{
                    	headerTemplate: "<span translate='modules.loan.actions'>",
                    	template: "<button kendo-tooltip k-content=\"'{{ 'entity.action.view' | translate }}'\" class='btn btn-default k-button-icontext' ui-sref='modules.loan.card.list.view({token: #:token#})'>"
                                + "<span class='glyphicon glyphicon glyphicon glyphicon-eye-open'></span>"
                                + "</button>"
                                ,
                        title: "&nbsp;", 
                        width: "10ch" 
                    }]
        };
      
       
    }
    
})();
