(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'eehNavigationProvider'];

    function stateConfig($stateProvider, eehNavigationProvider) {
        $stateProvider
	        .state('modules.loans', {
				url : '/loan',
				parent : 'modules',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'content@' : {
						templateUrl : 'app/modules/loan/loans.template.html',
						controller : 'LoansController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }]
				}
			})
        	.state('modules.loan', {
				url : '/loan/{id}',
				parent : 'modules',
				abstract: true,
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'content@' : {
						templateUrl : 'app/modules/loan/loan.template.html',
						controller : 'LoanMenuController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
	                menuWithHeader: ['$stateParams', 'Loan', function($stateParams, Loan) {
	                    return Loan.get({id : $stateParams.id}).$promise;
	                }]
				}
			})
	       	.state('modules.loan.condition', {
				url : '/conditions',
				parent : 'modules.loan',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'loan-header' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-body' : {
						templateUrl : 'app/modules/loan/condition/conditions.loan.template.html',
						controller : 'ConditionsLoanController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }]
				}
			})
			.state('modules.loan.payments', {
				url : '/payments',
				parent : 'modules.loan',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'loan-header' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-body' : {
						templateUrl : 'app/modules/loan/card/card.feaure.loan.template.html'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }]
				}
			})
			.state('modules.loan.schedules', {
				url : '/schedules',
				parent : 'modules.loan',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'loan-header' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-body' : {
						templateUrl : 'app/modules/loan/card/card.feaure.loan.template.html'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }]
				}
			})
	        .state('modules.loan.order-card', {
					url : '/order-card',
					parent : 'modules.loan',
					views : {
						'loan-header' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-body' : {
							templateUrl : 'app/modules/loan/card/card-holder.registration.loan.template.html',
							controller : 'CardHolderRegistrationLoanController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/loan');
		                	$translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }],
		                defaultValue : ['Card', function(Card) {
							return Card.getDefault().$promise;
						}]
					}
				})
				.state('modules.loan.card', {
					url : '/cards',
					parent : 'modules.loan',
					views : {
						'loan-header' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-body' : {
							templateUrl : 'app/modules/loan/card/card-holder.view.loan.template.html',
							controller : 'CardHolderLoanController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                    $translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }],
		                entity: ['Card', function(Card) {
		                    return Card.get().$promise;
		                }]
		            }
				})
				.state('modules.loan.card.edit', {
					url : '/edit',
					parent : 'modules.loan.card',
					views : {
						'loan-header@modules.loan' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-body@modules.loan' : {
							templateUrl : 'app/modules/loan/card/card-holder.edit.loan.template.html',
							controller : 'CardHolderLoanController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                    $translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }]
		            }
				})
				.state('modules.loan.card.list', {
					url : '/list',
					parent : 'modules.loan.card',
					views : {
						'loan-header@modules.loan' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-sub-header@modules.loan' : {
							templateUrl : 'app/modules/loan/card/card.list.loan.template.html',
							controller : 'CardListLoanController',
							controllerAs : 'vm'
						},
						'loan-body@modules.loan' : function(){
							return "";
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                    $translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }]
		            }
				
				})
				.state('modules.loan.card.list.add-card', {
					url : '/add-card',
					parent : 'modules.loan.card.list',
					views : {
						'loan-header' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
						},
						'loan-sub-header@modules.loan' : {
							templateUrl : 'app/modules/loan/card/card.list.loan.template.html',
							controller : 'CardListLoanController',
							controllerAs : 'vm'
						},
						'loan-body@modules.loan' : {
							templateUrl : 'app/modules/loan/card/card.feaure.loan.template.html'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                    $translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }]
		            }
				
				})
				.state('modules.loan.card.list.view', {
					url : '/{token}/view',
					parent : 'modules.loan.card.list',
					views : {
						'loan-header@modules.loan' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-sub-header@modules.loan' : {
							templateUrl : 'app/modules/loan/card/card.list.loan.template.html',
							controller : 'CardListLoanController',
							controllerAs : 'vm'
						},
						'loan-body@modules.loan' : {
							templateUrl : 'app/modules/loan/card/card.view.loan.template.html',
							controller : 'CardViewLoanController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                    $translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }]
		            }
				
				})
				.state('modules.loan.card.list.edit-status', {
					url : '/{token}/edit-status',
					parent : 'modules.loan.card.list',
					views : {
						'loan-header@modules.loan' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-sub-header@modules.loan' : {
							templateUrl : 'app/modules/loan/card/card.list.loan.template.html',
							controller : 'CardListLoanController',
							controllerAs : 'vm'
						},
						'loan-body@modules.loan' : {
							templateUrl : 'app/modules/loan/card/card.edit.status.loan.template.html',
							controller : 'CardEditStatusLoanController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                    $translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }]
		            }
				
				})
				.state('modules.loan.card.list.edit-pin', {
					url : '/{token}/edit-pin',
					parent : 'modules.loan.card.list',
					views : {
						'loan-header@modules.loan' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-sub-header@modules.loan' : {
							templateUrl : 'app/modules/loan/card/card.list.loan.template.html',
							controller : 'CardListLoanController',
							controllerAs : 'vm'
						},
						'loan-body@modules.loan' : {
							templateUrl : 'app/modules/loan/card/card.edit.pin.loan.template.html',
							controller : 'CardEditPinLoanController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                    $translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }]
		            }
				
				})
	        ;
        
        
    }
})();
