(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('LoansController', LoansController);

    LoansController.$inject = ['$scope', '$state', 'Loan', '$stateParams', 'defaultPageSize'];

    function LoansController ($scope, $state, Loan, $stateParams, defaultPageSize) {
        var vm = this;
        vm.kendo = kendo;
        console.log("LoansController ...");
        
        vm.loansDataSource = new kendo.data.DataSource({
        	schema: {
			      model: {
			        id: "id",
			        fields: {
			          "id": { type: "number", validation:{required:true} },
			          "loanDate": { type: "date" },
			          "firstName": { type: "string" },
			          "lastName": { type: "string" },
			          "fullName": { type: "string"},
			          "loanType": { type: "string"},
			          "loanSum": { type: "number" },
			          "loanStatus": { type: "string" }
			    	}
				},
                parse: function(d){
                	$.each(d, function (idx, elem) {
                        elem.fullName = elem.firstName + " " + elem.lastName;
                    });
                	return d;
                }
			},
            aggregate: [
              { field: "loanType", aggregate: "count"},
              { field: "loanSum", aggregate: "sum"}
            ],
			transport: {
				read: function(e){
					
					Loan.query(
							function(response){
								e.success(response);
							},
							function(error){
								console.log('loan list error');
								e.error(error);
							}
					);
				}
			},
			pageSize: defaultPageSize,
			sort: {
				field: "loanDate",
				dir: "desc"
			}
        });
        
        vm.loansOptions = {
        		scrollable: true,
                sortable: true,                
                pageable: {
                	refresh: true,
                	messages: {
                	      empty: "No data"
                	    }
                },
                filterable: {
                    extra: false,
                    operators: {
                        string: {
                            contains: "Contains",
                        }
                    }
                },
                toolbar: [
                          {	
                        	name: "excel", 
                        	text: "Excel"
                          }],
                excel: {
                    fileName: "loans.xlsx"
                },
                columns: [
					{
					    field: "loanType",
					    headerTemplate: "<span translate='modules.loan.table.loanType'>", 
					    aggregates: ["count"], 
                        footerTemplate: "Kiekis: #=count#",
					    width: "10ch"
					},{ 
						field: "loanStatus", 
						headerTemplate: "<span translate='modules.loan.table.loanStatus'>",
						width: "10ch"
					},{ 
						field: "loanNum", 
						headerTemplate: "<span translate='modules.loan.table.loanNum'>",
						width: "17ch"
					},{
	                    field: "loanDate",
	                    headerTemplate: "<span translate='modules.loan.table.loanDate'>",
	                    format: "{0:d}",
	                    width: "14ch",
	                    filterable: {
	                    	ui: "datepicker"
	                    }
                    },{
                        field: "fullName",
                        headerTemplate: "<span translate='modules.loan.table.fullName'>"
                    },{
                        field: "loanSum",
                        headerTemplate: "<span translate='modules.loan.table.loanSum'>",
                        format: "{0:n2}",
                        attributes: {class:"text-right"},
                        width: "11ch",
                        filterable: false,
                        aggregates: ["sum"], 
                        footerTemplate: "#=kendo.toString(sum, 'n2')#",
                        footerAttributes: { style: "text-align: right; " }
                    },{
                    	headerTemplate: "<span translate=''modules.loan.actions'>",
                    	template: "<button kendo-tooltip k-content=\"'{{ 'entity.action.view' | translate }}'\" class='btn btn-default k-button-icontext' ui-sref='modules.loan.condition({id: #:id#})'>"
                            + "<span class='glyphicon glyphicon glyphicon glyphicon-eye-open'></span>"
                            + "</button>"
                            ,
                    title: "&nbsp;", 
                    width: "10ch" 
                    }]
        };
        
        function tipasFilter(element) {
            element.kendoDropDownList({
                dataSource: {
                  data: tipai
                },
                optionLabel: "--Pasirink reikðmæ--"
                        });
        } 
        function busenaFilter(element) {
            element.kendoDropDownList({
                dataSource: {
                  data: busenos
                }, optionLabel: "--Pasirink reikðmæ--"
                        });
        } 
        
    }
    
})();
