(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('LoanMenuController', LoanMenuController);

    LoanMenuController.$inject = ['$scope', '$state', 'Loan', '$stateParams', 'eehNavigation', 'menuWithHeader', '$filter'];

    function LoanMenuController ($scope, $state, Loan, $stateParams, eehNavigation, menuWithHeader, $filter) {
        var vm = this;
        console.log("LoanMenuController ...");
        

        
        eehNavigation
		        .menuItem('sidenav.loan', {
		          text: 'Loan',
		          iconClass: 'fa-list-alt',
		          isCollapsed: false,
		          weight: -10
		        })
		        .menuItem('sidenav.loan.conditions', {
		          text: 'Conditions',
		          iconClass: 'fa-check-circle-o',
		          state: 'modules.loan.condition',
		          weight: -10,
		          isVisible: isVisible('modules.loan.condition')
		        })
		        .menuItem('sidenav.loan.payments', {
		          text: 'Payments',
		          iconClass: 'fa-money',
		          state: 'modules.loan.payments',
		          weight: -10,
		          isVisible: isVisible('modules.loan.payments')
		        })
		        .menuItem('sidenav.loan.schedules', {
		          text: 'Schedules',
		          iconClass: 'fa-calendar',
		          state: 'modules.loan.schedules',
		          weight: -8,
		          isVisible: isVisible('modules.loan.schedules')
		        })
		        .menuItem('sidenav.cards', {
		          text: 'Cards',
		          iconClass: 'fa-credit-card',
		          isCollapsed: false,
		          weight: -6,
		        })
		        .menuItem('sidenav.cards.order', {
			      text: 'Order Card',
			      iconClass: 'fa-handshake-o',
			      weight: -6,
			      state: 'modules.loan.order-card',
			      isVisible: isVisible('modules.loan.order-card')
			    })
		        .menuItem('sidenav.cards.holder', {
		          text: 'Card holder',
		          iconClass: 'fa-user-circle-o',
		          weight: -6,
		          state: 'modules.loan.card',
		          isVisible: isVisible('modules.loan.card')
		        })
		        .menuItem('sidenav.cards.list', {
		          text: 'Card\'s list',
		          iconClass: 'fa-credit-card',
		          weight: -6,
		          state: 'modules.loan.card.list',
		          isVisible: isVisible('modules.loan.card.list')
		        })
		        .iconBaseClass('fa');
        
        function isVisible(state) {
        	var isView = false;
            var menu = menuWithHeader[0].menu;
            var right = menuWithHeader[0].right;
            
            var currentMenu = $filter('filter')(menu, {state: state}, false)[0];
            
            if(currentMenu != null && currentMenu.state == state)
            	isView = true;
            else 
            	isView = false;

            var currentRight = $filter('filter')(right, {state: state}, false)[0];
            
            if(currentRight != null && currentRight.state == state && currentRight.isView && isView)
            	isView = true;
            else 
            	isView = false;
            
            return isView;

        }
        
    }
    
})();
