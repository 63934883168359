(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('ApplicationController', ApplicationController);

    ApplicationController.$inject = ['$stateParams', '$rootScope','$scope', '$state', 'entity', '$translate', 'Application', 'Calculator', 'AlertService', '$element', 'formHelper', '$location', '$interval', 'shortRefresh', 'longRefresh'];

    function ApplicationController ($stateParams, $rootScope, $scope, $state, entity, $translate, Application, Calculator, AlertService, $element, formHelper, $location, $interval, shortRefresh, longRefresh) {
    	
    	var setTestData = false; /* setting test data */

    	formHelper.setFocus('first-field');
    	var vm = this;
    	vm.isViewOnly = ($state.current.name).search("new") >= 0 ? false : true ;
    	vm.dateFormat = kendo.culture().calendar.patterns.d;
    	vm.dateMask = vm.dateFormat.replace("dd","00").replace("yyyy","0000").replace("MM","00");
    	vm.patternNationality = "[a-zA-Z]{2}|[a-zA-Z]{3}";
    	
    	$rootScope.$on('$translateChangeSuccess', function () {
    		vm.dateFormat = kendo.culture().calendar.patterns.d;
        });
    	
        vm.noTariffs = false;
        vm.tariff = {};
        vm.calculate = calculate;

    	
    	initCustomValidation();
    	
    	var mode = "view";
    	mode = ($state.current.name).search("new") >= 0 ? "new" : mode;
    	
    	vm.showSurety = false;
    	var first = mode == "new" ? true : false;
    	
    	vm.sexDataSource = new kendo.data.DataSource({
    		data: [
			{
    			key: "F",
    			value: $translate.instant("modules.application.female")
    		},{
    			key: "M",
    			value: $translate.instant("modules.application.male")
    		}]
    	});

    	vm.familyStatusDataSource = new kendo.data.DataSource({
    		data: [
			{
    			key: "SINGLE",
    			value: $translate.instant("modules.application.unmarried")
    		},{
    			key: "MARIED",
    			value: $translate.instant("modules.application.married")
    		},{
    			key: "CIVIL_MARIAGE",
    			value: $translate.instant("modules.application.union")
    		},{
    			key: "DIVORCED",
    			value: $translate.instant("modules.application.divorced")
    		},{
    			key: "WIDOWED",
    			value: $translate.instant("modules.application.widowed")
    		}]
    	});

    	vm.tariffData = new kendo.data.DataSource({
        	schema: {
			      model: {
			        id: "id",
			        fields: {
			        	"type": { type: "string" },
			        	"productType": { type: "string" },
			        	"personCode": { type: "string" },
			        	"name": { type: "string" },
			        	"priority": { type: "number" },
			        	"minAmount": { type: "number" },
			        	"maxAmount": { type: "number" },
			        	"defaultTrm": { type: "number" },
			        	"defaultAmount": { type: "number" }			    	}
				}
			},
			transport: {
				read: function(e){
				
					Calculator.query(
							{product : vm.application.productName, person_code: vm.clnt.personCode },
							function(response){
				        		vm.noTariffs = response.length > 0 ? false : true ;
								e.success(response);
							},
							function(error){
								console.log('calculator tariffs error');
								e.error(error);
							}
					).$promise;
				}
			},
			sort: {
				field: "priority",
				dir: "desc"
			}
        });
    	
    	

    	var testData = {
    		    "productName": "HERCUS_CARD",
    		    "applSum": 1000,
    		    "currency": "EUR",
    		    "trm": 5,
    		    "goodsName": "Mobils",
    		    "notes": "New mobils!!!!",
    		    "attDocs": "Fax",
    		    "supplicant": [{
    		        "supplicantType": "MAIN",
    		        "otherPhone": "+370 600 60601",
    		        "mobPhone": "+370 600 60600",
    		        "contactPhone": "+370 600 60602",
    		        "firstName": "Vardenis",
    		        "lastName": "Pavardenis",
    		        "personCode": "12345678910",
    		        "birthDate": "1991-12-31",
    		        "sex": "F",
    		        "familyStatus": "SINGLE",
    		        "passNumber": "XX123456789",
    		        "passExpire": "2021-12-31",
    		        "citizenship": "LAT",
    		        "email": "mail@domen.lv",
    		        "regPlace": "Riga",
    		        "regStreet": "Ozols",
    		        "regHouse": "1",
    		        "regPostcode": "0123",
    		        "livPlace": "Ventspils",
    		        "livStreet": "Zalgirs",
    		        "livHouse": "2",
    		        "livPostcode": "4321",
    		        "workName": "Seims",
    		        "workTitle": "Presedatels",
    		        "worksFrom": "2010-01-01",
    		        "workPlace": "Jurmals",
    		        "workStreet": "Marijus",
    		        "workHouse": "7",
    		        "workPostcode": "9874",
    		        "workIncome": 1000,
    		        "otherIncome": 10,
    		        "loanPay": 10,
    		        "dependantsCount": 2,
    		        "familyIncome": 1010,
    		        "familyLoanPay": 100,
    		        "contactFirstName": "Names",
    		        "contactLastName": "Surnames",
    		        "vsaaAllowed": 2
    		    },{
    		        "supplicantType": "SURETY",
    		        "otherPhone": "+370 600 60601",
    		        "mobPhone": "+370 600 60600",
    		        "contactPhone": "+370 600 60602",
    		        "firstName": "Vardenis",
    		        "lastName": "Pavardenis",
    		        "personCode": "12345678910",
    		        "birthDate": "1991-12-31",
    		        "sex": "F",
    		        "familyStatus": "SINGLE",
    		        "passNumber": "XX123456789",
    		        "passExpire": "2021-12-31",
    		        "citizenship": "LAT",
    		        "email": "mail@domen.lv",
    		        "regPlace": "Riga",
    		        "regStreet": "Ozols",
    		        "regHouse": "1",
    		        "regPostcode": "0123",
    		        "livPlace": "Ventspils",
    		        "livStreet": "Zalgirs",
    		        "livHouse": "2",
    		        "livPostcode": "4321",
    		        "workName": "Seims",
    		        "workTitle": "Presedatels",
    		        "worksFrom": "2010-01-01",
    		        "workPlace": "Jurmals",
    		        "workStreet": "Marijus",
    		        "workHouse": "7",
    		        "workPostcode": "9874",
    		        "workIncome": 1000,
    		        "otherIncome": 10,
    		        "loanPay": 10,
    		        "dependantsCount": 2,
    		        "familyIncome": 1010,
    		        "familyLoanPay": 100,
    		        "contactFirstName": "Names",
    		        "contactLastName": "Surnames",
    		        "vsaaAllowed": 1
    		    }]
    		};
    	
        vm.application = setTestData && mode == "new" ? testData : entity ;
        
        vm.application.currency = mode == "new" ? "EUR" : vm.application.currency ;
        
        var supplicants = vm.application.supplicant;
        angular.forEach(supplicants, function(supplicant){
            switch(supplicant.supplicantType) {
	    		case "MAIN":
	    			vm.clnt = supplicant;
	    			break;
	    		case "SURETY":
	    			vm.grnt = supplicant;
	    			vm.showSurety = mode == "new" ? false : true;
	    			initCustomGuarantorValidation();
	    			break;
	    		case "SPOUSE":
	    			vm.sps = supplicant;
	    			break;
            }	        	
        });
        
        vm.result = vm.application.result ? vm.application.result[0] : "";
        if(vm.result.isApplEditable && mode != "new"){
        	mode =  "edit";
        	vm.isViewOnly = false;
        }
        console.log("application controller in '" + mode + "' mode");
        
        vm.copyClntAddress = function(place,street,apartment,postCode){
        	if(vm.clnt.livPlace || vm.clnt.livStreet || vm.clnt.livHouse || vm.clnt.livPostcode) return;
        	if(place) vm.clnt.livPlace = place;
        	if(street) vm.clnt.livStreet = street; 
        	if(apartment) vm.clnt.livHouse = apartment;
        	if(postCode) vm.clnt.livPostcode = postCode;
        };
        
        vm.copyGrntAddress = function(place,street,apartment,postCode){
        	if(vm.grnt.livPlace || vm.grnt.livStreet || vm.grnt.livHouse || vm.grnt.livPostcode) return;
        	if(place) vm.grnt.livPlace = place;
        	if(street) vm.grnt.livStreet = street; 
        	if(apartment) vm.grnt.livHouse = apartment;
        	if(postCode) vm.grnt.livPostcode = postCode;
        };
        
        if(mode == "new") {
        	if($stateParams.tgrId != null || $stateParams.applSum != null) first = false;
        	if($stateParams.applSum != null) vm.application.applSum = $stateParams.applSum;
        	if($stateParams.personCode != null) vm.clnt.personCode = $stateParams.personCode;
        	if($stateParams.tgrId != null) vm.application.tgrId = $stateParams.tgrId;
        }
        
        loadTariffs(true);
        
        
        vm.loadTariffsAndCalculate = function(){
        	loadTariffs(false); 
        	calculate();
        };
        
        function loadTariffs(toCalculate){
        	vm.tariffData.read()
	        	.then(function(){

	        		var data = vm.tariffData.data();
	        		data.sort(function(a, b){
	        			  a = parseInt(a['priority']);
	        			  b = parseInt(b['priority']);
	        			  return b - a;
	        			});
	        		var item = data[0];
		        	vm.application.applSum = vm.application.applSum && !first ? vm.application.applSum : item.defaultAmount; 
		        	vm.application.tgrId = vm.application.tgrId && !first ? vm.application.tgrId : item.id;
		        	if(toCalculate) calculate();
	        	});
        }
        
        function calculate(){
        	var calculator = {};
        	calculator.applAmount = vm.application.applSum;
        	calculator.financedAmount = vm.application.applSum;
        	calculator.personCode = vm.clnt.personCode;
        	calculator.tariffId = vm.application.tgrId;
        	Calculator.send(calculator, function(response){
        		if(response) vm.application.loanPayTot = response.calcPayTot;
        	});
        }
        
        $scope.$watch(function() { return vm.application.tgrId;}, function(newVal, oldVal) {
        	if(newVal !== oldVal) { 
        		first = vm.clnt.personCode ? false : true ;
        		calculate();
        		}
        });
        
        vm.setSurety = function(){
        	if(!vm.showSurety) {
        		console.log('surety is ON');
        		vm.grnt = {
                    		supplicantType: "SURETY",
                    		otherPhone: "",
                    		mobPhone: ""
                    	};
        	  	vm.grntMobilePhoneRequired = true;
        		initCustomGuarantorValidation();
        	} else vm.grntMobilePhoneRequired = false;
        };
        
        if( mode != "new" ) {
        	var checkError = vm.result.checkError ? vm.result.checkError.toUpperCase() : "";
        	vm.resultMode = checkError == "OK" || checkError == "" ? getResultMode(vm.result.applStatus, vm.result.checkResult) : "blocked-error";
        	vm.requirements = vm.result.request_list ? vm.result.request_list.split('#') : "";
        }
        
        vm.submit = function () {
        	
        	$element.find('.form-group').each(function(){
        		var $formGroup = angular.element(this);
        		var $inputs = $formGroup.find('input[ng-model],textarea[ng-model],select[ng-model],bc-phone-number[ng-model],input[k-ng-model],select[k-ng-model]');
	            if ($inputs.length > 0) {
	              $inputs.each(function() {
	                  var $input = angular.element(this);
	                  if($input[0].tagName == "BC-PHONE-NUMBER") {
	                	  var id = $input[0].id;
	                	  if(!vm.showSurety && id.substring(0,4) == "grnt") {
	                		  eval('vm.'+id+'Required = false;');
	                		  eval('vm.'+id+'NoError = true;');
	                		  if($input.hasClass('ng-invalid')) eval('vm.applicationForm.'+id+'.$setValidity("phoneNumber", true);');
	                		  return true;
	                	  } else if(vm.showSurety && id == "grntOtherPhone") {
	                		  eval('vm.grntOtherPhoneRequired = false;');
	                		  eval('vm.grntOtherPhoneNoError = true;');
	                		  if($input.hasClass('ng-invalid')) eval('vm.applicationForm.grntOtherPhone.$setValidity("phoneNumber", true);');
	                		  return true;
	                	  }
	                	  if($input.hasClass('ng-invalid')) eval('vm.'+ id +'NoError = false;');
	                	  return true;
	                  }
	                  if($input[0].type == 'radio') {
	                	  var name = $input[0].name;
	                	  if(!vm.showSurety && name == "grntVsaa") return true;
	                	  if(typeof eval('vm.'+ name.substring(0,4) + '.vsaaAllowed') == "undefined") eval('vm.'+ name +'NoError = false;');
	                	  return true;
	                  }
	                  if($input.hasClass('ng-invalid') || $input.hasClass('k-invalid')) $formGroup.toggleClass('has-error', true);
	              });
	            }
        			
        	});
        	
        	console.log("submiting");
        	
        	if(vm.applicationForm.$invalid || !vm.validator.validate()){
        		console.log("Application is NOT VALID");
        		console.log(vm.grntOtherPhoneNoError);
            	if(!vm.clntMobilePhoneNoError) vm.clntMobilePhoneNoError = false;
            	if(!vm.clntOtherPhoneNoError) vm.clntOtherPhoneNoError = false;
            	if(vm.showSurety && !vm.grntMobilePhoneNoError) vm.grntMobilePhoneNoError = false;
            	if(vm.showSurety && !vm.grntOtherPhoneNoError) vm.grntOtherPhoneNoError = false;
            	if(!vm.clntContactPhoneNoError) vm.clntContactPhoneNoError = false;
        		
        		formHelper.setFocus('first-field');
        		return;
        	}
        	
        	console.log("Application is VALID");

        	vm.application.supplicant = [];
        	var supplicant = [];
        	supplicant.push(vm.clnt);
        	if(vm.showSurety) supplicant.push(vm.grnt); 
        	vm.application.supplicant = supplicant;
        	switch(mode) {
	        	case "new":
	        		Application.save(vm.application, 
	                    	function(result){
		                    	/*On success*/
		                    	AlertService.success("modules.alert.success.save");
		                    	console.log("saving application: success");
		                    	$location.update_path('/applications/'+result.id);
	                    	}, function(error){
	                    		/*On error*/
	                    		console.log("saving application: error");
	                    	});
	        		break;
	        	case "edit":
	        		Application.update(vm.application, 
	                    	function(){
	                    	/*On success*/
	                    	AlertService.success("modules.alert.success.update");
	                    	console.log("updating application: success");
	                    	//$state.go("modules.applications", {}, { reload: true });
	                    	}, function(){
	                    	/*On error*/
	                    		//initCustomValidation();
	                    		console.log("updating application: error");
	                    	});
	        		break;
	        	case "view":
	        	default:
        	};
        };

    	function initCustomValidation(){
        	vm.clntVsaaNoError = true;
        	vm.clntMobilePhoneNoError = true;
        	vm.clntOtherPhoneNoError = true;
        	vm.clntContactPhoneNoError = true;
    	}
    	function initCustomGuarantorValidation(){
	    	vm.grntVsaaNoError = true;
	    	vm.grntMobilePhoneNoError = true;
	    	vm.grntOtherPhoneNoError = true;
    	}
    	
    	function getResultMode(applStatus,checkResult){
    		var result = "";
    		switch(applStatus){
    			case "NEW":
	    		case "QUEUE_AUTO":
	    		case "QUEUE_MANUAL":
	    		case "IN_PROGRESS":
	    			result = "wait";
	    			break;	
	    		
	    		case "PROCESSED":
	    			switch(checkResult) {
	    				case "GRANTED":
	    					result = "granted";		
	    					break;
	    					
	    				case "REQUIREMENTS":
	    					result = "requirements";
	    					break;
	    					
	    				case "DENIED":
	    					result = "denied";
	    					break;
	    					
	    				default:
	    					result = "no-result";
	    			}
	    			break;
	    		
	    		case "CONTRACT_CREATED":
	    			result = "created";
	    			break;
	    			
	    		case "EXPIRED":
	    			result = "expired";
	    			break;
	    			
	    		case "BLOCKED":
	    		case "ERROR":
	    			result = "blocked-error";
	    			break;
    		}
    		return result;
    	}
    	
    	if(mode == "edit") {
    		startResultRefresh();
    	}
    	
    	function startResultRefresh(){
    		var run = $interval(reload, vm.refresh);
	        
	    	vm.refresh = vm.resultMode == "wait" ? shortRefresh : longRefresh;
	    	
	        function reload(){
	        	console.log('refreshing ... ' + vm.refresh );
	        	Application.getResult(
	        			{id : $stateParams.id},
	        			function(response){
							vm.result = response;
							var checkError = vm.result.checkError ? vm.result.checkError.toUpperCase() : "";
				        	vm.resultMode = checkError == "OK" || checkError == "" ? getResultMode(vm.result.applStatus, vm.result.checkResult) : "blocked-error";
				        	vm.requirements = vm.result.request_list ? vm.result.request_list.split('#') : "";
					        vm.refresh = vm.resultMode == "wait" ? shortRefresh : longRefresh;
						},
						function(error){
							console.log('error on refresh result');
							if(error.data.err) {
								$interval.cancel(run);
								vm.resultMode = "server-error";
								vm.errorMessage = error.data.msg;
							}
						}).$promise;
	        }
	        
	        $scope.$watch(function() { return vm.refresh;}, function(newVal, oldVal) {
	        	$interval.cancel(run);
	        	run = $interval(reload, vm.refresh);
	        }, true);
	        $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) { 
	        	$interval.cancel(run);
	        });
    	}
    }
})();
