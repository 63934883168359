(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('CardEditStatusLoanController', CardEditStatusLoanController);

    CardEditStatusLoanController.$inject = ['$scope', '$state', 'Card', 'formHelper', 'entity', '$stateParams', '$filter'];

    function CardEditStatusLoanController ($scope, $state, Card, formHelper, entity, $stateParams, $filter) {
        var vm = this;
        vm.kendo = kendo;
        console.log("CardEditStatusLoanController");
        
        console.log($stateParams.id);
        if($stateParams.id != null) {
        vm.cardholder = entity.cardholder[0];
        vm.cards = vm.cardholder.card;
        
        vm.currentCard = $filter('filter')(vm.cards, {token: $stateParams.id}, false)[0];
        }

        vm.statusDataSource = new kendo.data.DataSource({
    		data: [
			{
    			key: "1",
    			value: "STATUS 1"
    		},{
    			key: "2",
    			value: "STATUS 2"
    		}]
    	});
        
        vm.save = function(){
        	$state.go('modules.loan.card.list.view');
        };
        
        vm.cancel = function(){
        	$state.go('modules.loan.card.list.view');
        };
        
    }
    
})();
