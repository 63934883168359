(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .component('address', {
          templateUrl: "app/modules/address.template.html",
    	  controller: addressController,
    	  bindings: {
    	    place: '=',
    	    street: '=',
    	    apartment: '=',
    	    postCode: '=',
    	    ngReadonly: '=',
    	    label: '=',
    	    ngRequired: '=',
    	    form: '='
    	  }
    	});

    	addressController.$inject = ['$scope', '$element', '$attrs'];
        
        function addressController($scope, $element, $attrs) {
    	  var ctrl = this;
    	  
    	}

    	
})();
