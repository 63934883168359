(function() {
	'use strict';
	angular.module('sigmaApp').factory('Application', Application);

	Application.$inject = [ '$resource', 'baseUrl', 'DateUtils', '$filter'];

	function Application($resource, baseUrl, DateUtils, $filter) {
		var resourceUrl = baseUrl + 'api/applications/:id';

		return $resource(resourceUrl,{},
				{
					'query' : {
						method : 'GET',
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'get' : {
						method : 'GET',
						isArray : false,
						transformResponse : function(data) {
							if (data) {
								data = angular.fromJson(data);
							}
							return data;
						}
					},
					'getResult' : {
						method : 'GET',
						url: resourceUrl + '/result',
						isArray : false,
						transformResponse : function(data) { 
							if (data) {
								data = angular.fromJson(data);
							}
							return data;
						}
					},
					'update': {
						method:'PUT',
						transformRequest : function(data) {
							angular.forEach(
											data.supplicant,
											function(supplicant) {
												supplicant.passExpire = DateUtils.convertLocalDateToServer(supplicant.passExpire);
												supplicant.birthDate = DateUtils.convertLocalDateToServer(supplicant.birthDate);
												supplicant.worksFrom = DateUtils.convertLocalDateToServer(supplicant.worksFrom);
											});
							data.applDate = DateUtils.convertLocalDateToServer(data.applDate);
							return angular.toJson(data);
						},
						transformResponse : function(data) {
							var response = {};
							if(data) {
								response = angular.fromJson(data);
								if(response.err === "Error") {
									var msg = "";
									angular.forEach(response.message.split('|'), function(item){
										msg += $filter('translate')(item) + '\n'; 
									});
									response.message = msg;
								}
							}
							return response;
						}
					},
					'save' : {
						method : 'POST',
						transformRequest : function(data) {
							angular.forEach(
											data.supplicant,
											function(supplicant) {
												supplicant.passExpire = DateUtils.convertLocalDateToServer(supplicant.passExpire);
												supplicant.birthDate = DateUtils.convertLocalDateToServer(supplicant.birthDate);
												supplicant.worksFrom = DateUtils.convertLocalDateToServer(supplicant.worksFrom);
											});
							data.applDate = DateUtils.convertLocalDateToServer(data.applDate);
							return angular.toJson(data);
						},
						transformResponse : function(data) {
							var response = {};
							if(data) {
								response = angular.fromJson(data)
								if(response.err === "Error") {
									var msg = "";
									angular.forEach(response.message.split('|'), function(item){
										msg += $filter('translate')(item) + '\n'; 
									});
									response.message = msg;
								}
							}
							return response;
						}
					},
				// 'delete': { method: 'DELETE'}
				});
	}
})();